import React from 'react';

function VideoComponent(props) {
  return (
    <video
      style={props.style}
      autoPlay
      muted
      loop
      playsInline
      // loading="lazy"
      preload="metadata"
      // poster={props.poster}
      >
      {/* <source src={`https://storage.googleapis.com/find-me-sales-bucket${props.src}.webm`} type="video/webm" /> */}
      <source
        src={`https://storage.googleapis.com/find-me-sales-bucket${props.src}.mp4`}
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  );
}

export default VideoComponent;
